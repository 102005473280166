<template>
  <div>
    <b-form-group class="m-0 p-0">
      <div v-for="(choice, index) in choices" :key="index" class="mb-4">
        <b-form-radio v-if="choice.display == true" v-model="selectedChoice" :value="index" @change="onChange">
          <h4 class="subtitle-form mb-3">{{ choice.title }}</h4>
        </b-form-radio>

        <component
          :is="choice.component.is"
          v-bind="choice.component.props"
          v-if="selectedChoice == index && choice.display == true"
          :storeEditAction="storeEditAction"
          :storeReadGetter="storeReadGetter"
          :storeEditedGetter="storeEditedGetter"
        ></component>
      </div>
    </b-form-group>
  </div>
</template>

<script>
import DetailUpdateMixin from "@/mixins/DetailUpdateMixin";

export default {
  mixins: [DetailUpdateMixin],
  data() {
    return {
      selectedChoice: null,
    };
  },
  props: {
    choices: {
      type: Array,
      default: () => [],
    },
    selected: {
      type: Number,
      default: 0,
    },
    onChange: {
      type: Function,
      default: () => {},
    },
    onInit: {
      type: Function,
      default: () => {},
    },
  },
  mounted() {
    this.selectedChoice = this.selected;
    this.onInit();
  },
};
</script>

<style lang="scss" scoped></style>
